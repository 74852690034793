import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import dynamic from 'next/dynamic';

import boardItemTypes from '@constants/boardItemTypes';
import ShareModalContext, { SHARETYPE } from '@context/Modal/share-modal-context';
import UserContext from '@context/User/user-context';
import useAlert, { TYPES } from '@hooks/useAlert/useAlert';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import PRODUCT_QUERY from '@graphql/VendorVariant/queries/get-product-modal.graphql';
import { SIMILAR_PRODUCTS_PDP } from '@graphql/VendorVariant/queries/get-similar-products.graphql';
import SIMILAR_WITH_ALTERNATES_QUERY from '@graphql/VendorVariant/queries/get-similar-products-with-alternates.graphql';
import ExploreProductModalContext, { IExploreProductModal } from '@context/Modal/explore-product-modal-context';
import AddToBoardModalContext from '@context/Modal/add-to-board-modal-context';
import { ISimilarProduct } from '@models/VendorVariant/i-similar-product';

import {
  GetSimilarProductsWithAlternatesQuery
} from '@graphql/VendorVariant/queries/get-similar-products-with-alternates.graphql.gen';
import prepareAlternativesForAnalytics from '../BoardBuilderModalProvider/helpers/prepareAlternativesForAnalytics';

/* Dynamically loading this prevents it from being used as shared js
  between explore and room/home which was breaking both pages */
const ExploreProductModalNoSSR = dynamic(
  () => import('@components/shared/modals/ProductModal/ExploreProductModal'),
  { ssr: false }
);

const trackSimilarProductClick = (uuid: string, similarProducts: any[], clickedProduct: any) => {
  const listPosition = similarProducts.findIndex((product) => product.id === clickedProduct.id);
  analyticsTrack(
    'product_alternate_clicked',
    {
      listId: uuid,
      listPosition,
      productName: clickedProduct.title,
      vendorVariantId: clickedProduct.id,
    },
  );
};

interface ExploreProductModalProviderProps {
  designerName?: string;
}

export default function ExploreProductModalProvider({
  children,
  designerName
}: PropsWithChildren<ExploreProductModalProviderProps>) {
  const [exploreProductModalState, setExploreProductModalState] =
    useState<IExploreProductModal | null>(null);
  const [uuid, setUuid] = useState('');
  const {
    user,
    savedVendorVariantIds,
  } = useContext(UserContext);
  const { open: openShareModal } = useContext(ShareModalContext);
  const { open: openSaveModal } = useContext(AddToBoardModalContext);
  const { error, data, loading } = useQuery(PRODUCT_QUERY, {
    variables: { id: exploreProductModalState?.initialVendorVariantId },
    skip: !exploreProductModalState,
  });

  const getAlternates = exploreProductModalState && exploreProductModalState.sourceBoardId;

  const { data: similarData } =
    useQuery(getAlternates ? SIMILAR_WITH_ALTERNATES_QUERY : SIMILAR_PRODUCTS_PDP, {
      variables: {
        id: exploreProductModalState?.initialVendorVariantId,
        pageSize: 12,
        sourceBoardId: exploreProductModalState?.sourceBoardId,
      },
      skip: !exploreProductModalState,
    });

  const similarProducts = getSimilarProducts(similarData, exploreProductModalState?.sourceBoardId);

  const errorAlert = useAlert(TYPES.ERROR);

  useEffect(() => {
    if (error || (
      !!(exploreProductModalState?.initialVendorVariantId)
      && !loading
      && !(data?.vendorVariantById)
    )) {
      errorAlert('Failed to load product.', 3000);
      setExploreProductModalState(null);
    }
  }, [error, exploreProductModalState?.initialVendorVariantId, loading, data?.vendorVariantById]);

  useEffect(() => {
    if (similarProducts && similarProducts.length) {
      const newUuid = uuidv4();
      setUuid(newUuid);
      analyticsTrack(
        'product_list_viewed',
        {
          listId: newUuid,
          listType: 'product_alternate',
          products: prepareAlternativesForAnalytics(similarProducts),
          vvId: data?.vendorVariantById?.id,
          vvName: data?.vendorVariantById?.title,
          vvDesignerRecommended: !!exploreProductModalState?.isDesignerRecommended,
        },
      );
    }
  }, [similarData]);

  const getBoardId = (userId: number) => {
    let boardId;

    if (exploreProductModalState!.sourceBoardId) {
      boardId = exploreProductModalState!.sourceBoardId.toString();
    }

    if (exploreProductModalState!.projectBoardCommission) {
      if (userId === exploreProductModalState!.projectBoardCommission.userId) {
        boardId = exploreProductModalState!.projectBoardCommission.boardId.toString();
      }
    }

    return boardId;
  };

  const handleShare = () => {
    openShareModal({
      modelId: exploreProductModalState!.initialVendorVariantId,
      shareType: SHARETYPE.PRODUCT,
      url: data?.vendorVariantById?.productUrl
    });
  };

  const handleSave = () => {
    const imageUrl = data?.vendorVariantById.images[0].uri;
    openSaveModal({
      saveItemId: Number(exploreProductModalState!.initialVendorVariantId),
      saveItemImageUrl: imageUrl,
      saveItemType: boardItemTypes.VENDOR_VARIANTS,
      source: 'product_modal',
    });
  };

  const handleSimilarProductClick = (product: ISimilarProduct) => {
    trackSimilarProductClick(uuid, similarProducts, product);
    setExploreProductModalState({
      initialVendorVariantId: product.id,
      readOnly: exploreProductModalState!.readOnly,
      analyticsListId: uuid,
      source: exploreProductModalState!.source,
      isDesignerRecommended: (product as any).isDesignerRecommended,
    });
  };

  const [triggeredAddToCart, setTriggeredAddToCart] = useState(false);
  const onTriggeredAddToCart = (value: boolean) => {
    setTriggeredAddToCart(value);
  };

  const [isDoneAddingToCart, setIsDoneAddingToCart] = useState(false);
  const onDoneAddingToCart = (value: boolean) => {
    setIsDoneAddingToCart(value);
  };

  return (
    <ExploreProductModalContext.Provider
      value={{
        open: setExploreProductModalState,
        isClosed: exploreProductModalState === null,
        onTriggeredAddToCart,
        triggeredAddToCart,
        onDoneAddingToCart,
        isDoneAddingToCart
      }}
    >
      {exploreProductModalState && data?.vendorVariantById && (
        <ExploreProductModalNoSSR
          {...exploreProductModalState}
          onClose={() => setExploreProductModalState(null)}
          onSimilarProductClick={handleSimilarProductClick}
          onSave={handleSave}
          onShare={handleShare}
          saved={ savedVendorVariantIds[exploreProductModalState.initialVendorVariantId] }
          similarVendorVariants={similarProducts}
          vendorVariant={data?.vendorVariantById}
          boardId={user ? getBoardId(Number(user.id)) : undefined}
          onTriggeredAddToCart={onTriggeredAddToCart}
          triggeredAddToCartState={triggeredAddToCart}
          onDoneAddingToCart={onDoneAddingToCart}
          designerName={designerName}
        />
      )}
      {children}
    </ExploreProductModalContext.Provider>
  );
}

function getSimilarProducts(
  similarData: GetSimilarProductsWithAlternatesQuery,
  sourceBoardId?: number
) {
  if (similarData?.similarVendorVariants) {
    return similarData.similarVendorVariants.items.map((item) => {
      return { ...item, isDesignerRecommended: false, sourceBoardId };
    });
  }

  if (similarData?.designerRecommendationsByVendorVariant) {
    return similarData?.designerRecommendationsByVendorVariant.map((item: any) => {
      return { ...item, isDesignerRecommended: true, sourceBoardId };
    });
  }

  return [];
}
