import React from 'react';
import env from '@lib/envs/env';
import { getApollo } from '@lib/apollo/apolloClient';
import useUpdatePreview from '@lib/prismic/util/useUpdatePreviewRef';
import { fetchFeaturedBoardsFromPrismicDoc } from '@components/pages/ShopLanding/model/featuredBoards';
import Home from '@components/pages/Home/Home';
import Compose from '@components/shared/Compose';
import LoginRegisterModalProvider
  from '@components/shared/providers/LoginRegisterModalProvider/LoginRegisterModalProvider';
import ShareModalProvider from '@components/shared/providers/ShareModalProvider/ShareModalProvider';
import ExploreProductModalProvider
  from '@components/shared/providers/ExploreProductModalProvider/ExploreProductModalProvider';
import VideoModalProvider from '@components/shared/providers/VideoModalProvider/VideoModalProvider';
import ThemeProvider from '@components/theme/ThemeProvider';
import HavenlyAtHomeModalProvider from '@components/shared/providers/HavenlyAtHomeModalProvider/HavenlyAtHomeModalProvider';
import DefaultTemplate from '@components/shared/templates/DefaultTemplate/DefaultTemplate';
import getPrismicStaticProps, { extractProps } from '@lib/prismic/getPrismicStaticProps';
import { GetStaticProps } from 'next';
import { useTheme } from '@components/theme';
import getInstagramPosts from '@lib/instagram/client';

const REVALIDATE_INTERVAL_SECONDS = env.STATIC_PAGE_DEFAULT_CACHE_SECONDS;

export default function HomePage(props: any) {
  const {
    data, featuredBoards, instagramFeeds, previewRef, prismicDoc
  } = props;
  useUpdatePreview(previewRef, prismicDoc.id);
  const theme = useTheme();
  const metaData = getMetaData();
  const { description, title } = metaData;
  return (
    <Compose
      components={[
        ThemeProvider,
        LoginRegisterModalProvider,
        ShareModalProvider,
        ExploreProductModalProvider,
        VideoModalProvider,
        HavenlyAtHomeModalProvider,
      ]}
    >
      <DefaultTemplate
        canonicalLink={`https://${env.DOMAIN_NAME}`}
        data-test="home-page"
        title={title}
        description={description}
        navProps={{
          isTransparent: false,
          isFixed: true,
          isConflictingVariant: false
        }}
        facebook={metaData}
        keywords={[
          'interior design',
          'interior designer',
          'home design',
        ]}
        jsonSchema={getJsonSchema()}
        className={theme.styles.Body}
      >
        <Home
          prismicData={data}
          featuredBoards={featuredBoards}
          instagramFeeds={instagramFeeds}
        />
      </DefaultTemplate>
    </Compose>
  );
}

const PRISMIC_TYPE = 'home-page';

export const getStaticProps: GetStaticProps = async (context) => {
  const prismicStaticProps = await getPrismicStaticProps({
    docType: PRISMIC_TYPE,
  })(context);

  const prismicProps = extractProps(prismicStaticProps);
  if (!prismicProps) {
    return prismicStaticProps;
  }

  const apolloClient = getApollo();

  const featuredBoards = await fetchFeaturedBoardsFromPrismicDoc(apolloClient, prismicProps);

  const instagramFeeds = await getInstagramPosts();

  return {
    ...prismicStaticProps,
    props: {
      ...prismicProps,
      featuredBoards,
      instagramFeeds
    },
    revalidate: REVALIDATE_INTERVAL_SECONDS
  };
};

function getMetaData() {
  return {
    site_name: 'Havenly',
    image: 'https://s3.amazonaws.com/havenlywebsite/havenly_meta_image.jpg',
    url: 'https://havenly.com/',
    type: 'article',
    title: 'Online Interior Design and Home Decorating | Havenly',
    description: 'Online interior design for real people. Begin your room project for as low as $79.00.',
    price_amount: undefined,
    price_currency: undefined,
  };
}

function getJsonSchema() {
  return {
    '@context': 'http://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        name: 'Havenly',
        legalName: 'Havenly, Inc.',
        url: 'https://www.havenly.com/',
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://www.havenly.com/#logo',
          inLanguage: 'en-US',
          url: 'http://havenly.com/img/branding/touch-icon-192x192.png',
          caption: 'Havenly',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.havenly.com/#logo',
            inLanguage: 'en-US',
            url: 'http://havenly.com/img/branding/touch-icon-192x192.png',
            caption: 'Havenly',
          },
          potentialAction: [
            {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: 'https://www.havenly.com/?s={search_term_string}&utm_source=google',
              },
              'query-input': {
                '@type': 'PropertyValueSpecification',
                valueRequired: 'http://schema.org/True',
                valueName: 'search_term_string',
              },
            },
          ],
          about: {
            '@type': 'Organization',
            '@id': 'https://www.havenly.com/#organization',
            name: 'Havenly',
            url: 'https://www.havenly.com/',
            logo: {
              '@type': 'ImageObject',
              '@id': 'https://www.havenly.com/#logo',
              inLanguage: 'en-US',
              url: 'http://havenly.com/img/branding/touch-icon-192x192.png',
              caption: 'Havenly',
              image: {
                '@type': 'ImageObject',
                '@id': 'https://www.havenly.com/#logo',
                inLanguage: 'en-US',
                url: 'http://havenly.com/img/branding/touch-icon-192x192.png',
                caption: 'Havenly',
              },
            },
          },
        },
        foundingDate: '2014',
        sameAs: [
          'https://www.facebook.com/theHavenly',
          'https://twitter.com/theHavenly',
          'https://www.pinterest.com/thehavenly/',
          'https://www.linkedin.com/company/havenly',
          'https://www.instagram.com/theHavenly/',
          'https://www.wikidata.org/wiki/Q96636373',
        ],
        founder: {
          '@type': 'Person',
          name: 'Lee Mayer',
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress: '3501 Wazee St #200',
          addressLocality: 'Denver',
          addressRegion: 'CO',
          postalCode: '80216',
          addressCountry: {
            '@type': 'Country',
            name: 'USA',
          },
        },
      },
      {
        '@type': 'Corporation',
        name: 'Havenly',
        url: 'http://havenly.com',
        logo: 'http://havenly.com/img/branding/touch-icon-192x192.png',
        sameAs: [
          'https://www.facebook.com/theHavenly',
          'https://twitter.com/theHavenly',
          'https://www.instagram.com/theHavenly/',
          'https://www.pinterest.com/thehavenly/',
          'https://www.wikidata.org/wiki/Q96636373',
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '~+1-888-978-3152',
          contactType: 'Customer Service',
          areaServed: 'US',
          availableLanguage: 'EN',
        },
      },
      {
        '@type': 'WebPage',
        '@id': 'https://www.havenly.com/#webpage',
        url: 'https://www.havenly.com/',
        name:
            'Online interior design for real people | Begin your room project for as low as $79.00',
        inLanguage: 'en-US',
        primaryImageOfPage: {
          '@type': 'ImageObject',
          inLanguage: 'en-US',
          potentialAction: {
            '@type': 'ReadAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https:www.havenly.com/',
            },
          },
        },
        isPartOf: {
          '@type': 'WebSite',
          '@id': 'https://www.havenly.com/#website',
          url: 'https://www.havenly.com/',
          name: 'Havenly',
          description: 'Online Interior Design and Home Decorating',
          inLanguage: 'en-US',
        },
        publisher: {
          '@type': 'Organization',
          '@id': 'https://www.havenly.com/#organization',
          name: 'Havenly',
          url: 'https://www.havenly.com/',
        },
      },
    ],
  };
}
